<template>
  <form @submit.prevent="process()">
    <div class="m-b-30">
      <b-form-group :label="$t('group_form.group_title')+'*'">
        <b-form-input v-model="detailedServicesGroup.name" required></b-form-input>
        <div class="text-danger" v-if="!$v.detailedServicesGroup.name.maxLength">{{ $t("errors.name_max_length") }}</div>
      </b-form-group>
      <b-form-group  :label="$t('group_form.group_description')">
        <b-form-textarea placeholder="" v-model="detailedServicesGroup.description"></b-form-textarea>
        <div class="text-danger" v-if="!$v.detailedServicesGroup.description.maxLength">{{ $t("errors.name_max_length") }}</div>
      </b-form-group>
    </div>
    <h3>{{ $t('group_form.users') }}
      <a href="#" @click.prevent="selectUsers">
        <small>{{ typeof this.detailedServicesGroup.users_ids !== 'undefined' && detailedServicesGroup.users_ids.length ? $t('group_form.select_nothing'):$t('group_form.select_all') }}</small>
      </a>
    </h3>
    <div v-if="isLoading">
      <b-skeleton width="30%" :animated="true"></b-skeleton>

      <b-skeleton width="40%" :animated="true"></b-skeleton>

      <b-skeleton width="80%" :animated="true"></b-skeleton>

      <b-skeleton :animated="true"></b-skeleton>
    </div>
    <div v-else class="sources-columns users-columns m-b-30 border-checkbox-section">
      <div
          v-for="user in users"
          :key="user.id" class="border-checkbox-group border-checkbox-group-primary" style="width: 100%">
        <input type="checkbox" class="border-checkbox" :id="`user_${user.id}`" v-model="detailedServicesGroup.users_ids"
               :value="user.id">
        <label :for="`user_${user.id}`" class="border-checkbox-label">
          {{ user.login }} ({{user.first_name}} {{user.last_name}})
          <router-link class="user-link"
                       :to="{name: 'UserDetail', params: {id: user.id}}">
            <i class="feather icon-info"></i>
          </router-link>
        </label>
      </div>
    </div>

    <h3>{{ $t('group_form.services') }}
      <a
          href="#"
          @click.prevent="selectServices">
        <small>
          {{ typeof detailedServicesGroup.services_ids !== 'undefined' && detailedServicesGroup.services_ids.length ? $t('group_form.select_nothing'):$t('group_form.select_all') }}
        </small>
      </a>
    </h3>
<!--    <b-form-group>-->
<!--      <b-form-select :placeholder="$t('group_form.order')" v-model="order">-->
<!--        <b-form-select-option v-for="(item, k) in sorting" :key="k" :value="item.value">{{item.item}}</b-form-select-option>-->
<!--      </b-form-select>-->
<!--    </b-form-group>-->
    <div v-if="isLoading">
      <b-skeleton width="30%" :animated="true"></b-skeleton>

      <b-skeleton width="40%" :animated="true"></b-skeleton>

      <b-skeleton width="80%" :animated="true"></b-skeleton>

      <b-skeleton :animated="true"></b-skeleton>
    </div>
    <div v-else class="sources-columns border-checkbox-section">
      <div class="group" v-for="group in Object.keys(sorted)" :key="group">
        <h3 class="groupTitle" style="margin-bottom: 3px">{{$t(`services.${group}`)}}</h3>
        <div
            v-for="service in sorted[group]"
            :key="service.id" class="border-checkbox-group border-checkbox-group-primary " style="width: 100%;padding-bottom: 3px">

          <input type="checkbox" class="border-checkbox" :id="`service_${service.id}`" v-model="detailedServicesGroup.services_ids"
                 :value="service.id">

          <label :for="`service_${service.id}`" class="border-checkbox-label checkboxFlex">
            <img :src="service.icon_url" class="service-icon">
            <div style="margin-left: 4px">{{ service.name }}</div>
            <span v-if="!service.is_active" style="margin-left: 4px">({{$t(`service.disabled`)}})</span>
          </label>
        </div>
      </div>


    </div>
    <br>
    <p class="text-danger" v-if="error.length">{{ $t('errors.' + error) }}</p>
    <b-button type="submit" variant="primary" class="btn"
      :class="{'btn-primary': $v.detailedServicesGroup.$invalid, 'btn-primary': !$v.detailedServicesGroup.$invalid }"
      :disabled="$v.detailedServicesGroup.$invalid">
      {{ payloadProcess === 'create' ? $t('group_form.save_new') : $t('group_form.save') }}
      <b-spinner small v-if="sending"></b-spinner>
    </b-button>
    <router-link :to="{name: 'Services'}" class="btn btn-secondary m-l-10">{{$t('modal.cancel')}}</router-link>

  </form>
</template>

<script>
import {required, maxLength} from "vuelidate/lib/validators";
import loadUserServices from "@/mixins/loadUserServices";


import 'core-js/actual'

export default {
  name: "serviceEditForm",
  mixins:[loadUserServices],
  props: ['detailedServicesGroup', 'payloadProcess'],
  data() {
    return {
      isLoading: false,
      order: "up_name",
      sorted: {},
      payload: {},
      error: "",
      sending: false
    };
  },
  validations() {
    return {
      detailedServicesGroup: {
        name: {
          required,
          maxLength: maxLength(255)
        },
        description: {
          maxLength: maxLength(255)
        }
      }
    }
  },
  methods: {
    process() {
      for(const key of Object.keys(this.detailedServicesGroup)) {
        if(this.detailedServicesGroup[key]) {
          this.payload[key] = this.detailedServicesGroup[key];
        }
      }
      switch (this.payloadProcess) {
        case 'create':
          this.create();
          break;
        case 'edit':
          this.edit();
          break;
      }
    },
    create() {
      this.sending = true;
      this.$store.dispatch('service_groups/createServiceGroup', this.payload).then(() => {
        this.$router.push({name: 'Services'});
      }).catch(e => {
        const message = e.response.data.message;
        if(message === 'The name has already been taken.') {
          this.error = 'data_group_taken';
        } else {
          this.error = message;
        }
      }).finally(() => {
        this.sending = false;
      })
    },
    edit() {
      this.sending = true;
      this.$store.dispatch('service_groups/updateServiceGroup', this.payload).then(() => {
        this.$router.push({name: 'Services'});
      }).finally(() => {
        this.sending = false;
      });
    },

    selectUsers() {
      if(typeof this.detailedServicesGroup.users_ids !== 'undefined' && this.detailedServicesGroup.users_ids.length) {
        this.detailedServicesGroup.users_ids = [];
      } else {
        this.detailedServicesGroup.users_ids = this.users.map(user => user.id);
      }
    },
    selectServices() {
      if(typeof this.detailedServicesGroup.services_ids !== 'undefined' && this.detailedServicesGroup.services_ids.length) {
        this.detailedServicesGroup.services_ids = [];
      } else {
        this.detailedServicesGroup.services_ids = this.services.map(service => service.id);
      }
    },
  },
  watch: {
    order(value) {
      this.$store.dispatch('service_groups/changeOrderServices', value);
    },
    '$i18n.locale': {
      handler() {
        this.isLoading = true
        this.$store.dispatch('service_groups/getServices', {order_by: "released_at", order_type: "desc"})
            .then(() => {
              this.isLoading = false;
            })
      }
    }
  },
}
</script>

<style lang="scss">
h2 {
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
}
h3 {
  font-weight: 700;
  margin-bottom: 10px;
}
.users-columns {
  column-count: 2;
  @media (max-width: 992px) {
    column-count: 1;
  }
}
.users-columns {
  position: relative;

  .border-checkbox-section .border-checkbox-group .border-checkbox-label{
    margin-bottom: 10px;
    height: 60px;
  }
  .user-link {
    display: inline-block;
  }
  .b-border-checkbox-group border-checkbox-group-primarybox.checkbox {
    width: 100%;
    .check {
      margin-right: calc(0.75em - 1px);
    }
    .control-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0;
      position: relative;
      &:hover {
        background-color: #fff;
        overflow: visible;
        text-overflow: initial;
        text-decoration: underline;
        z-index: 99;
        padding-right: 20px;
      }
    }
  }
  @media (max-width: 992px) {
    // &.users-columns {
      .b-border-checkbox-group border-checkbox-group-primarybox.checkbox {
        width: calc(100% - 35px);
      }
    // }
  }
  .icon {
    svg {
      width: 16px;
    }
  }
  .groupTitle{
    font-size: 16px;
  }

}
@media (min-width: 993px) {
  .border-checkbox-group.border-checkbox-group-primary {
    display: block;
  }
}


iframe {
  width: 100%;
  height: calc(100vh - 60px);
}
img.service-icon {
  width: 20px;
}
.group{
  margin-bottom: 8px;
}
.checkboxFlex{
  display: flex !important;
}

</style>